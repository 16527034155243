import React from 'react';
import { graphql } from "gatsby";
import Layout from '../components/layout';
import Nav from '../components/nav';
import SEO from '../components/seo';

import './dive-site.css';

const lang = {
  en: {
    built: 'Built',
    sank: 'Sank',
    depth: 'Depth',
    type: 'Type',
  },
  sv: {
    built: 'Byggd',
    sank: 'Sjönk',
    depth: 'Djup',
    type: 'Typ',
  }
}

const DiveSitesTemplate = (props) => (
  <Layout>
    <SEO
      title={props.data.contentfulDiveSites.seoTitle}
      description={props.data.contentfulDiveSites.seoDescription}
      keywords={props.data.contentfulDiveSites.seoKeywords}
    />
    <Nav />
    <div className="dive-sites__header">
      <div className="dive-sites__hero" style={{ backgroundImage: `url(${props.data.contentfulDiveSites.featuredImage.fluid.src})` }} ></div>
      <div className="dive-sites__info">
        <h1 className="dive-sites__title">{props.data.contentfulDiveSites.title}</h1>
        {props.data.contentfulDiveSites.wreckInfo &&
          <table className="dive-sites__ship-info-container">
            <tbody className="dive-sites__ship-info">
              <tr>
                <td>{lang.en.built}</td>
                <td>{props.data.contentfulDiveSites.wreckInfo.built}</td>
              </tr>
              <tr>
                <td>{lang.en.sank}</td>
                <td>{props.data.contentfulDiveSites.wreckInfo.sank}</td>
              </tr>
              <tr>
                <td>{lang.en.depth}</td>
                <td>{props.data.contentfulDiveSites.wreckInfo.depth}</td>
              </tr>
              <tr>
                <td>{lang.en.type}</td>
                <td>{props.data.contentfulDiveSites.wreckInfo.type}</td>
              </tr>
            </tbody>
          </table>
        }
      </div>
    </div>
    <div className="dive-sites__wrapper">
      <div className="dive-sites__content">
        <div dangerouslySetInnerHTML={
          { __html: `${props.data.contentfulDiveSites.content.childMarkdownRemark.html}` }
        } />
      </div>
    </div>
  </Layout>
)

export default DiveSitesTemplate;
export const query = graphql`
  query DiveSitesTemplate($id: String!) {
    contentfulDiveSites(id: {eq: $id}) {
      title
      id
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      wreckInfo {
        built
        sank
        depth
        type
      }
      seoTitle
      seoDescription
      seoAuthor
      seoKeywords
      seoImage {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
          src
        }
      }
      featuredImage {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
          src
        }
      }
    }
  }
`